import AddressService from '../../services/AddressService'

const state = () => ({
    loadingAddresses: false,
    address: null,
    addresses: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_address: false,
    datalistEstados: [
        { "sigla": "AC", "nome": "Acre" },
        { "sigla": "AL", "nome": "Alagoas" },
        { "sigla": "AP", "nome": "Amapá" },
        { "sigla": "AM", "nome": "Amazonas" },
        { "sigla": "BA", "nome": "Bahia" },
        { "sigla": "CE", "nome": "Ceará" },
        { "sigla": "DF", "nome": "Distrito Federal" },
        { "sigla": "ES", "nome": "Espírito Santo" },
        { "sigla": "GO", "nome": "Goiás" },
        { "sigla": "MA", "nome": "Maranhão" },
        { "sigla": "MT", "nome": "Mato Grosso" },
        { "sigla": "MS", "nome": "Mato Grosso do Sul" },
        { "sigla": "MG", "nome": "Minas Gerais" },
        { "sigla": "PA", "nome": "Pará" },
        { "sigla": "PB", "nome": "Paraíba" },
        { "sigla": "PR", "nome": "Paraná" },
        { "sigla": "PE", "nome": "Pernambuco" },
        { "sigla": "PI", "nome": "Piauí" },
        { "sigla": "RJ", "nome": "Rio de Janeiro" },
        { "sigla": "RN", "nome": "Rio Grande do Norte" },
        { "sigla": "RS", "nome": "Rio Grande do Sul" },
        { "sigla": "RO", "nome": "Rondônia" },
        { "sigla": "RR", "nome": "Roraima" },
        { "sigla": "SC", "nome": "Santa Catarina" },
        { "sigla": "SP", "nome": "São Paulo" },
        { "sigla": "SE", "nome": "Sergipe" },
        { "sigla": "TO", "nome": "Tocantins" }
    ],
})
  
const mutations = {
    SET_ADDRESS: (state, payload) => {
        state.address = payload
    },
    SET_ADDRESSES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.addresses = payload.items
        }else{
            state.addresses = [...state.addresses, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_ADDRESSES: (state, payload) => {
        state.loadingAddress = payload
    },
    SET_SHOW_MODAL_ADDRESS: (state, payload) => {
        state.show_modal_new_address = payload
    },
}

const actions = {
    async fetchAddress({commit}, id){
        try{
            commit('SET_LOADING_ADDRESSES', true)
            const response = await AddressService.getOne(id);
            commit('SET_ADDRESS', response.data)
            commit('SET_LOADING_ADDRESSES', false)
        } catch (error) {
            commit('SET_LOADING_ADDRESSES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchAddresses({commit, rootState}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_ADDRESSES', true)
            const response = await AddressService.get(keyword, pageNumber, order);
            commit('SET_ADDRESSES', response.data)
            rootState.user.user.UserAddresses = response.data.items;
            commit('SET_LOADING_ADDRESSES', false)
        } catch (error) {
            commit('SET_LOADING_ADDRESSES', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createAddress({commit, dispatch}, data){
        try{
            commit('SET_LOADING_ADDRESSES', true)
            const response = await AddressService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchAddresses', {keyword: ''})
            commit('SET_SHOW_MODAL_ADDRESS', false)
            commit('SET_LOADING_ADDRESSES', false)
        } catch (error) {
            commit('SET_LOADING_ADDRESSES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeAddress({commit,dispatch}, data){
        try{
            commit('SET_LOADING_ADDRESSES', true)
            const response = await AddressService.update(data);
            dispatch('fetchAddresses', {keyword: ''})
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_ADDRESSES', false)
        } catch (error) {
            commit('SET_LOADING_ADDRESSES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async searchAddress({ commit }, cep) {
        try {
            const response = await AddressService.getViaCep(cep);
            if (response.data.erro) {
                commit('SET_ALERT', {
                    heading: 'error',
                    message: 'CEP não encontrado.'
                });
            } else {
                return response.data;
            }
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: 'Não foi possível buscar o endereço.'
            });
        }
    },
    async deleteAddress({commit, dispatch}, addressId){
        try{
            commit('SET_LOADING_ADDRESSES', true)
            const response = await AddressService.delete(addressId);
            dispatch('fetchAddresses', {keyword: ''})
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_ADDRESSES', false)
        } catch (error) {
            commit('SET_LOADING_ADDRESSES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getAddress: state => state.address,
    getAddresses: state => state.addresses,
    getLoadingAddress: state => state.loadingAddress,
    getDatalistEstados: state => state.datalistEstados,
    getShowModalNewAddress: state => state.show_modal_new_address
}

export default {
    state,
    getters,
    mutations,
    actions
};