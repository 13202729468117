import Api from './Api'

export default {
    login(credentials) {
        return Api().post('auth/login', credentials);
    },
    associarUser(credentials) {
        return Api().post('users/associarAPI', credentials);
    },
    getUser(id){
        return Api().get(`users/${id}`);
    },
    create(formData){
        return Api().post('users/register', formData);
    },
    updatePhones(phones){
        return Api().put('users/update_phones', phones);
    },
    updateProfileImage(image){
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return Api().post(`users/upload-image`, { image }, { headers });
    }
}